import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VDataTable, {
    attrs: {
      "headers": _vm.positionHeaders,
      "items": _vm.document.positions,
      "single-expand": _vm.singleExpand,
      "expanded": _vm.expanded,
      "show-expand": ""
    },
    on: {
      "update:expanded": function ($event) {
        _vm.expanded = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.sumPrice",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(_vm.getSumFormatted(item)) + " ")];
      }
    }, {
      key: "top",
      fn: function () {
        return [_c(VRow, [_c(VCol, {
          attrs: {
            "cols": "12",
            "xs": "12",
            "sm": "6",
            "md": "6",
            "lg": "4",
            "xl": "4"
          }
        }, [_c('DocumentPositionSearch', {
          on: {
            "positionAdded": _vm.expandPosition
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "expanded-item",
      fn: function ({
        headers,
        item
      }) {
        return [_c('td', {
          attrs: {
            "colspan": headers.length
          }
        }, [_c(VRow, {
          staticClass: "mt-1"
        }, [_c(VCol, {
          attrs: {
            "cols": "12",
            "xs": "12",
            "sm": "6",
            "md": "6",
            "lg": "6",
            "xl": "6"
          }
        }, [_c(VAutocomplete, {
          attrs: {
            "items": item.possibleCharacteristics,
            "dense": "",
            "item-text": "id",
            "return-object": "",
            "label": "Sachmerkmal",
            "prepend-inner-icon": "mdi-shape",
            "outlined": "",
            "rules": [v => !!v || 'Sachmerkmal muss ausgewählt werden'],
            "required": ""
          },
          on: {
            "change": () => _vm.changedCharacteristic(item)
          },
          model: {
            value: item.characteristic,
            callback: function ($$v) {
              _vm.$set(item, "characteristic", $$v);
            },
            expression: "item.characteristic"
          }
        })], 1), _c(VCol, {
          attrs: {
            "cols": "12",
            "xs": "12",
            "sm": "6",
            "md": "6",
            "lg": "6",
            "xl": "6"
          }
        }, [_c(VTextField, {
          attrs: {
            "label": "Menge",
            "prepend-inner-icon": "mdi-counter",
            "outlined": "",
            "dense": "",
            "suffix": item.quantityUnit.code,
            "disabled": item.characteristic == null,
            "rules": _vm.quantityRules,
            "required": ""
          },
          model: {
            value: item.quantity,
            callback: function ($$v) {
              _vm.$set(item, "quantity", $$v);
            },
            expression: "item.quantity"
          }
        })], 1)], 1), _c(VRow, {
          staticClass: "mt-n6 mb-n6"
        }, [_c(VCol, {
          attrs: {
            "cols": "12",
            "xs": "12",
            "sm": "6",
            "md": "6",
            "lg": "2",
            "xl": "2"
          }
        }, [_c('v-currency-field', {
          attrs: {
            "label": "Nettopreis",
            "prepend-inner-icon": "mdi-cash",
            "outlined": "",
            "dense": "",
            "suffix": _vm.document.billing.currency !== undefined ? _vm.currencyFormatService.getCurrencySymbol(_vm.document.billing.currency.name) : '',
            "rules": _vm.netPriceRules,
            "required": "",
            "disabled": item.characteristic == null
          },
          on: {
            "change": function ($event) {
              return _vm.calculateGrossPrice(item);
            }
          },
          model: {
            value: item.pricing.netPrice,
            callback: function ($$v) {
              _vm.$set(item.pricing, "netPrice", $$v);
            },
            expression: "item.pricing.netPrice"
          }
        })], 1), _c(VCol, {
          attrs: {
            "cols": "12",
            "xs": "12",
            "sm": "6",
            "md": "6",
            "lg": "3",
            "xl": "3"
          }
        }, [_c(VAutocomplete, {
          attrs: {
            "items": item.pricing.possibleValueAddedTaxes,
            "item-text": "name",
            "dense": "",
            "return-object": "",
            "label": "Umsatzsteuer",
            "prepend-inner-icon": "mdi-currency-eur",
            "outlined": "",
            "rules": [v => !!v || 'Umsatzsteuer muss ausgewählt werden'],
            "required": ""
          },
          on: {
            "change": function ($event) {
              return _vm.calculateGrossPrice(item);
            }
          },
          model: {
            value: item.pricing.valueAddedTax,
            callback: function ($$v) {
              _vm.$set(item.pricing, "valueAddedTax", $$v);
            },
            expression: "item.pricing.valueAddedTax"
          }
        })], 1), _c(VCol, {
          attrs: {
            "cols": "12",
            "xs": "12",
            "sm": "6",
            "md": "6",
            "lg": "2",
            "xl": "2"
          }
        }, [_c('v-currency-field', {
          attrs: {
            "label": "Bruttopreis",
            "dense": "",
            "prepend-inner-icon": "mdi-cash",
            "outlined": "",
            "suffix": _vm.document.billing.currency !== undefined ? _vm.currencyFormatService.getCurrencySymbol(_vm.document.billing.currency.name) : '',
            "rules": _vm.grossPriceRules,
            "required": "",
            "disabled": item.characteristic == null
          },
          on: {
            "change": function ($event) {
              return _vm.calculateNetPrice(item);
            }
          },
          model: {
            value: item.pricing.grossPrice,
            callback: function ($$v) {
              _vm.$set(item.pricing, "grossPrice", $$v);
            },
            expression: "item.pricing.grossPrice"
          }
        })], 1), _c(VCol, {
          attrs: {
            "cols": "12",
            "xs": "12",
            "sm": "6",
            "md": "6",
            "lg": "2",
            "xl": "2"
          }
        }, [_c(VTextField, {
          attrs: {
            "label": "Rabatt",
            "prepend-inner-icon": "mdi-percent",
            "outlined": "",
            "dense": "",
            "suffix": "%",
            "rules": _vm.discountRules,
            "required": "",
            "disabled": item.characteristic == null
          },
          model: {
            value: item.pricing.discount,
            callback: function ($$v) {
              _vm.$set(item.pricing, "discount", $$v);
            },
            expression: "item.pricing.discount"
          }
        })], 1), _c(VCol, {
          attrs: {
            "cols": "12",
            "xs": "12",
            "sm": "6",
            "md": "6",
            "lg": "3",
            "xl": "3"
          }
        }, [_c('v-currency-field', {
          attrs: {
            "label": "Gesamtpreis",
            "outlined": "",
            "dense": "",
            "prepend-inner-icon": "mdi-cash",
            "value": _vm.getSum(item),
            "suffix": _vm.document.billing.currency !== undefined ? _vm.currencyFormatService.getCurrencySymbol(_vm.document.billing.currency.name) : '',
            "disabled": ""
          }
        })], 1)], 1)], 1)];
      }
    }, {
      key: "item.actions",
      fn: function ({
        item
      }) {
        return [_c(VIcon, {
          on: {
            "click": function ($event) {
              return _vm.removePosition(item);
            }
          }
        }, [_vm._v("mdi-cart-remove")])];
      }
    }])
  }, [_c('template', {
    slot: "body.append"
  }, [_c('tr', [_c('td'), _c('td', {
    staticClass: "font-weight-bold",
    attrs: {
      "align": "left"
    }
  }, [_vm._v("Gesamt")]), _c('td'), _c('td', [_c(VCol, {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "12",
      "md": "12",
      "lg": "4",
      "xl": "4"
    }
  }, [_c(VTextField, {
    staticClass: "mb-n7 ml-n5 flex-shrink-1 flex-grow-0",
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "* Kopfrabatt",
      "prepend-inner-icon": "mdi-percent",
      "required": "",
      "rules": _vm.discountRules
    },
    model: {
      value: _vm.document.billing.discount,
      callback: function ($$v) {
        _vm.$set(_vm.document.billing, "discount", $$v);
      },
      expression: "document.billing.discount"
    }
  })], 1)], 1), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.getPositionsSum()))]), _c('td')])])], 2)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };