import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VCard, {
    attrs: {
      "flat": ""
    }
  }, [_c(VCardText, [_c(VForm, {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.valid,
      callback: function ($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('p', {
    staticClass: "primary--text text-h4 font-weight-black ml-6"
  }, [_vm._v(" " + _vm._s(_vm.documentTypeName) + " für " + _vm._s(_vm.customerName) + " ")]), _c(VExpansionPanels, {
    attrs: {
      "multiple": "",
      "accordion": "",
      "value": _vm.expanded
    }
  }, [_c('LBAExpansionPanel', {
    attrs: {
      "text-color": 'primary',
      "bgColor": 'white',
      "titleType": 'headline',
      "sideBar": false
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Allgemein")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('DocumentGeneral')];
      },
      proxy: true
    }])
  }), _c('LBAExpansionPanel', {
    attrs: {
      "text-color": 'primary',
      "bgColor": 'white',
      "titleType": 'headline',
      "sideBar": false
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Liefer- und Zahlungsdetails")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('DocumentDeliveryBillingDetails')];
      },
      proxy: true
    }])
  }), _c('LBAExpansionPanel', {
    attrs: {
      "text-color": 'primary',
      "bgColor": 'white',
      "titleType": 'headline',
      "sideBar": false,
      "disabled": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Verordnungen "), _c('span', {
          staticClass: "ml-3"
        }, [_c(VChip, [_c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" mdi-bullhorn-outline ")]), _vm._v(" Bald Verfügbar ")], 1)], 1)];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('DocumentPrescription')];
      },
      proxy: true
    }])
  }), _c('LBAExpansionPanel', {
    attrs: {
      "text-color": 'primary',
      "bgColor": 'white',
      "titleType": 'headline',
      "sideBar": false
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Positionen")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('DocumentPositions')];
      },
      proxy: true
    }])
  })], 1), _c(VContainer, [_c(VRow, {
    staticClass: "d-flex"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "align": "center"
    }
  }, [_c('LBAButton', {
    on: {
      "click": function ($event) {
        return _vm.createDocument();
      }
    }
  }, [_vm._v("Erstellen")])], 1)], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };