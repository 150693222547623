import "core-js/modules/es.array.push.js";
import DocumentGeneral from '../../components/business/DocumentGeneral.vue';
import DocumentDeliveryBillingDetails from '../../components/business/BusinessDeliveryBilling.vue';
import DocumentPrescription from '../../components/business/DocumentPrescription.vue';
import DocumentPositions from '../../components/business/DocumentPositions.vue';

//import { DocumentClass } from '../../models/business/BusinessClasses'
import { mapState } from 'vuex';
import axios from 'axios';
import addressService from '@/services/crm/addressService.js';
export default {
  components: {
    DocumentGeneral,
    DocumentDeliveryBillingDetails,
    DocumentPrescription,
    DocumentPositions
  },
  data: () => ({
    expanded: [0, 1, 3],
    valid: false,
    customer: '',
    documentTypeName: '',
    rate: 0,
    errors: {}
  }),
  computed: {
    ...mapState({
      document: state => state.businessDocument.document
    }),
    customerName() {
      if (this.customer.firstname !== undefined && this.customer.lastname !== undefined) {
        return this.customer.firstname + ' ' + this.customer.lastname;
      }
      return this.customer.name;
    }
  },
  methods: {
    async createDocument() {
      await this.$refs.form.validate();
      if (this.valid) {
        this.requestDocumentCreation();
      }
    },
    requestDocumentCreation() {
      console.warn(this.document.customerId);
      axios.post(`v1/business/document/create`, this.document).then(response => {
        this.$router.push({
          path: '/crm/addresses/' + response.data.customerId + '/documents/' + response.data.wfCid
        });
      });
    }
  },
  mounted() {
    axios.get('/v1/business/document/information/default').then(res => {
      this.$store.dispatch('setDocument', res.data);
      window.scrollTo(0, 0);
      this.document.customerId = this.$route.query.address;
      console.warn(this.document.customerId);
      addressService.getAddress(this.document.customerId).then(res => {
        this.customer = res.data;
      });
      this.document.typeId = this.$route.query.documentTypeId;
      if (this.document.typeId == 30) this.documentTypeName = 'Angebot';else if (this.document.typeId == 35) this.documentTypeName = 'Auftrag';
    });
  },
  destroyed() {
    //this.document = DocumentClass.getEmptyDocument()
  }
};