import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "md": "6",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('LBADatepickerInput', {
    attrs: {
      "label": "Lieferdatum"
    },
    model: {
      value: _vm.document.delivery.date,
      callback: function ($$v) {
        _vm.$set(_vm.document.delivery, "date", $$v);
      },
      expression: "document.delivery.date"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "md": "6",
      "lg": "4",
      "xl": "4"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.deliveryTypes,
      "item-text": "name",
      "return-object": "",
      "label": "* Versandart",
      "prepend-inner-icon": "mdi-truck-delivery-outline",
      "outlined": "",
      "rules": [v => !!v || 'Versandart muss ausgewählt werden'],
      "required": ""
    },
    model: {
      value: _vm.document.delivery.type,
      callback: function ($$v) {
        _vm.$set(_vm.document.delivery, "type", $$v);
      },
      expression: "document.delivery.type"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "md": "6",
      "lg": "4",
      "xl": "4"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.deliveryConditions,
      "item-text": "name",
      "return-object": "",
      "label": "* Lieferbedingungen",
      "prepend-inner-icon": "mdi-map-marker-outline",
      "outlined": "",
      "rules": [v => !!v || 'Lieferbedingungen müssen ausgewählt werden'],
      "required": ""
    },
    model: {
      value: _vm.document.delivery.condition,
      callback: function ($$v) {
        _vm.$set(_vm.document.delivery, "condition", $$v);
      },
      expression: "document.delivery.condition"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "md": "6",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('LBADatepickerInput', {
    attrs: {
      "label": "Zahlungsdatum"
    },
    model: {
      value: _vm.document.billing.date,
      callback: function ($$v) {
        _vm.$set(_vm.document.billing, "date", $$v);
      },
      expression: "document.billing.date"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "md": "6",
      "lg": "4",
      "xl": "4"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.billingTypes,
      "item-text": item => item.id + ' ' + item.name,
      "return-object": "",
      "label": "Zahlungsart",
      "prepend-inner-icon": "mdi-credit-card-outline",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.document.billing.type,
      callback: function ($$v) {
        _vm.$set(_vm.document.billing, "type", $$v);
      },
      expression: "document.billing.type"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "md": "6",
      "lg": "4",
      "xl": "4"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.billingConditions,
      "item-text": "name",
      "return-object": "",
      "label": "* Zahlungsbedingungen",
      "prepend-inner-icon": "mdi-map-marker-outline",
      "outlined": "",
      "rules": [v => !!v || 'Zahlungsbedingung muss ausgewählt werden'],
      "required": ""
    },
    model: {
      value: _vm.document.billing.condition,
      callback: function ($$v) {
        _vm.$set(_vm.document.billing, "condition", $$v);
      },
      expression: "document.billing.condition"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "md": "6",
      "lg": "4",
      "xl": "4"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.billingCurrencies,
      "item-text": "description",
      "return-object": "",
      "label": "* Währung",
      "prepend-inner-icon": "mdi-currency-eur",
      "outlined": "",
      "rules": [v => !!v || 'Währung muss ausgewählt werden'],
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "item-text",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(item.symbol + ' -c ' + item.description) + " ")];
      }
    }, {
      key: "item",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(item.symbol + ' - ' + item.description) + " ")];
      }
    }]),
    model: {
      value: _vm.document.billing.currency,
      callback: function ($$v) {
        _vm.$set(_vm.document.billing, "currency", $$v);
      },
      expression: "document.billing.currency"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };