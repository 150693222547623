import "core-js/modules/es.array.push.js";
import axios from 'axios';
import { mapState } from 'vuex';
import DocumentPositionSearch from './businessSearch/DocumentPositionSearch.vue';
import currencyFormatService from '@/services/formatters/currencyFormatService.js';
export default {
  components: {
    DocumentPositionSearch
  },
  data: () => ({
    expanded: [],
    singleExpand: true,
    currencyFormatService,
    positionHeaders: [{
      text: 'Artikelnummer',
      align: 'start',
      value: 'articleInformation.artNr'
    }, {
      text: 'Artikelbezeichnung',
      align: 'start',
      value: 'articleInformation.articleDescription'
    }, {
      text: 'Sachmerkmal',
      align: 'start',
      value: 'characteristic.id'
    }, {
      text: 'Gesamtpreis',
      align: 'start',
      value: 'sumPrice'
    }, {
      text: 'Aktionen',
      align: 'center',
      value: 'actions'
    }],
    quantityRules: [v => !!v || 'Menge muss eingegeben werden', v => /^[0-9]+$/.test(v) || 'Menge muss eine Zahl sein'],
    netPriceRules: [v => !!v || 'Nettopreis muss eingegeben werden'],
    grossPriceRules: [v => !!v || 'Bruttopreis muss eingegeben werden'],
    discountRules: [v => /^-?\d+(?:[.,]\d+)?$/.test(v) || 'Rabatt muss eine Zahl sein']
  }),
  computed: {
    ...mapState({
      document: state => state.businessDocument.document
    })
  },
  methods: {
    calculateNetPrice(position) {
      position.pricing.netPrice = position.pricing.grossPrice / (position.pricing.valueAddedTax.tax + 1);
    },
    getSum(position) {
      if (position.pricing !== undefined && position.pricing.netPrice !== undefined && position.pricing.grossPrice !== undefined && position.quantity !== undefined) {
        return position.pricing.grossPrice * position.quantity * (1 - position.pricing.discount * 0.01);
      }
      return '';
    },
    getSumFormatted(position) {
      if (position.pricing !== undefined && position.pricing.netPrice !== undefined && position.pricing.grossPrice !== undefined && position.quantity !== undefined) {
        let sum = position.pricing.grossPrice * position.quantity;
        let discountedSum = sum * (1 - position.pricing.discount * 0.01);
        let discountedValue = discountedSum - sum;
        if (discountedValue > 0 || discountedValue < 0) {
          return this.formatCurrency(discountedSum) + ' (' + this.formatCurrency(discountedValue) + ')';
        }
        return this.formatCurrency(discountedSum);
      }
      return '';
    },
    getPositionsSum() {
      let sum = 0.0;
      this.document.positions.forEach(p => {
        sum += p.pricing.grossPrice * p.quantity * (1 - p.pricing.discount * 0.01);
      });
      let discountedSum = sum * (1 - this.document.billing.discount * 0.01);
      let discountedValue = discountedSum - sum;
      if (!isNaN(discountedSum)) {
        if (discountedValue < 0 || discountedValue > 0) {
          return this.formatCurrency(discountedSum) + ' (' + this.formatCurrency(discountedValue) + ')';
        }
        return this.formatCurrency(sum);
      }
      return '';
    },
    formatCurrency(value) {
      if (this.document.billing.currency !== undefined) {
        return currencyFormatService.format(value, this.document.billing.currency.name);
      }
      return currencyFormatService.format(value, '');
    },
    calculateGrossPrice(position) {
      console.warn(position);
      position.pricing.grossPrice = position.pricing.netPrice * (position.pricing.valueAddedTax.tax + 1);
    },
    async changedCharacteristic(position) {
      var articleId = position.articleInformation.artNr;
      var characteristicId = position.characteristic.id;
      var possibleValueAddedTaxes = position.pricing.possibleValueAddedTaxes;
      await axios.get(`v1/business/document/positions/article/${articleId}/characteristic/${characteristicId}/pricing`).then(response => {
        position.pricing = response.data;
      });
      console.warn(position.pricing);
      position.pricing.possibleValueAddedTaxes = possibleValueAddedTaxes;
    },
    removePosition(item) {
      this.document.positions = this.document.positions.filter(position => {
        return item.id !== position.id;
      });
    },
    expandPosition(pos) {
      let temp = [];
      temp.push(pos);
      this.expanded = temp;
    }
  }
};